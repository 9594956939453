import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useStore } from '@nanostores/react'
import { userData } from '@stores/userStore'
import { isMobileMenuOpen } from '@stores/menuStore'
import User from '@components/Icons/User.jsx'
import UserSolid from '@components/Icons/UserSolid.jsx'
import Gift from '@components/Icons/Gift.jsx'
import OpenBox from '@components/Icons/OpenBox'
import Person from '@components/Icons/Person.jsx'
import { registryData } from '@stores/registryStore'
import { chord } from '@api/chord.js'
import { simulateClick } from '@utils/utils.js'

const NavAccountMobile = () => {
    const $userData = useStore(userData || null)
    const $isMobileMenuOpen = useStore(isMobileMenuOpen)
    const [haveUser, setHaveUser] = useState(false)
    const [show, setShow] = useState(false)
    const [hasRegistry, setHasRegistry] = useState(false)
    const ref = useRef(null)

    // console.log('userData in NavAccount', $userData)

    /* prevents flash of unlogged-in user in nav */
    useEffect(() => {
        if ($userData && $userData.email) {
            setHaveUser(true)
        } else {
            setHaveUser(false)
        }
    }, [$userData])

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!ref.current?.contains(event.target)) {
                setShow(false)
            }
        }

        window.addEventListener('mousedown', handleOutSideClick)

        return () => {
            window.removeEventListener('mousedown', handleOutSideClick)
        }
    }, [ref])

    useEffect(() => {
        if (localStorage.getItem('hasRegistry') === 'true') {
            setHasRegistry(true)
        } else {
            setHasRegistry(false)
        }
    }, [])

    const handleNavAccountClick = () => {
        setShow(!show)

        const main = document.querySelector('main')
        main.classList.remove('menu-overlay')
        const menuClose = document.querySelector('.menu-close')
        simulateClick(menuClose)
    }

    const handleLogout = () => {
        userData.set()
        registryData.set({})
        localStorage.removeItem('ma_user')
        chord.reset();
        localStorage.setItem('hasRegistry', 'false')
        document.cookie =
            'access_token=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT'
        document.cookie =
            'ma_registry_id=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT'
        localStorage.removeItem('registry_token')
        localStorage.removeItem('registryId')
        setTimeout(() => {
            window.location.href = '/'
        }, 1000)
    }

    return (
        <div className="account-wrap h-full" ref={ref}>
            <div className="nav-account-outer mr-2 relative flex items-center justify-center h-full">
                <div
                    className="nav-account-inner h-full cursor-pointer"
                    onClick={handleNavAccountClick}
                >
                    {haveUser ? (
                        <UserSolid width="w-[20px]" />
                    ) : (
                        <User width="w-[20px]" />
                    )}
                </div>
            </div>
            <div
                className={`account-outer z-10 absolute top-16 m-auto left-0 right-0 w-[90vw] ${
                    show ? 'flex' : 'hidden'
                }`}
            >
                <div className="account-inner p-3 bg-beige text-flint flex-col w-full">
                    <div className="flex pl-3 flex-col">
                        {/* add customer logic here */}
                        {!haveUser ? (
                            <div className="logged-out flex flex-col text-sm items-start justify-start ">
                                <div className="create-account flex items-center justify-start my-2 hover:text-ash hover:font-bold">
                                    <div className="account-icon w-[24px] ">
                                        <Person width="w-[11px]" />
                                    </div>
                                    <a
                                        className="hover:text-ash"
                                        href="/account/register"
                                    >
                                        Create Account
                                    </a>
                                </div>
                                {/* <div className="start-a-registry flex items-center justify-start my-2">
                                    <div className="account-icon w-[24px] -left-[4px] relative">
                                        <OpenBox width="w-[21px]" />
                                    </div>
                                    <a
                                        href="/pages/registry"
                                        className="nav-utility-registry hover:text-ash"
                                    >
                                        {hasRegistry
                                            ? 'Your Registry'
                                            : 'Start a Registry'}
                                    </a>
                                </div> */}
                                {/* <span className="pipe mx-2">
                                    <span className="mx-2">|</span>
                                </span> */}
                                <div className="log-in flex items-center justify-start my-2">
                                    <div className="account-icon w-[24px]">
                                        <User width="w-[16px]" />
                                    </div>
                                    <a className="" href="/account/login">
                                        Sign In
                                    </a>
                                </div>
                            </div>
                        ) : null}
                        {haveUser ? (
                            <div className="logged-in flex flex-col text-sm items-start justify-start">
                                <div className="user-account flex items-center justify-start my-2 hover:text-ash hover:font-bold">
                                    <a
                                        className="flex items-center justify-start hover:text-ash"
                                        href="/account"
                                    >
                                        <div className="account-icon w-[24px] ">
                                            <Person width="w-[11px]" />
                                        </div>
                                        Hi,{' '}
                                        {$userData && $userData.firstName
                                            ? $userData.firstName + '!'
                                            : ''}
                                    </a>
                                </div>
                                {/* <div className="start-a-registry flex items-center justify-start my-2 hover:text-ash hover:font-bold">
                                    <div className="account-icon w-[24px] -left-[4px] relative">
                                        <OpenBox width="w-[21px]" />
                                    </div>
                                    <a
                                        href="/pages/registry"
                                        className="nav-utility-registry hover:text-ash"
                                    >
                                        {hasRegistry
                                            ? 'Your Registry'
                                            : 'Start a Registry'}
                                    </a>
                                </div> */}

                                <div className="log-in flex items-center justify-start my-2 hover:text-ash hover:font-bold">
                                    <div className="account-icon w-[24px]">
                                        <User width="w-[16px]" />
                                    </div>
                                    <a className="" href="/account">
                                        Your Account
                                    </a>
                                </div>
                                <div className="log-out flex items-center justify-start my-2 hover:text-ash hover:font-bold">
                                    <span className="" onClick={handleLogout}>
                                        Log Out
                                    </span>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavAccountMobile

{
    /*}{% if customer.metafields.baby_registry.registry_availability == "True" %}
                            <a href="/pages/registry" className="nav-utility-registry">Your Registry</a>
                        {% else %}
                            <a href="/pages/m-a-registry" className="nav-utility-registry">Start a Registry</a>
                        {% endif %}
                        */
}
